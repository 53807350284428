import { useEffect, useState } from "react";

import LastScannedLicensePlate from "../../../stores/parking/spot/LastScannedLicensePlate";
import { Vehicle } from "../../../storybook";

const useLastScannedLicensePlate = (
  lastScannedLicensePlate: LastScannedLicensePlate
) => {
  const [vehicle, setVehicle] = useState<Vehicle>({
    code: "",
    country: "NL",
  });

  const updateVehicle = (newVehicle: Vehicle) => {
    setVehicle(newVehicle);
  };

  useEffect(() => {
    if (!lastScannedLicensePlate.createdAt || vehicle.code !== "") return;

    const now = new Date().getTime();
    const diff = (now - lastScannedLicensePlate.createdAt) / 1000;

    if (diff > lastScannedLicensePlate.licensePlateClearDelay) {
      updateVehicle({ code: "" } as Vehicle);
    } else {
      updateVehicle({
        code: lastScannedLicensePlate.recentLicenseRef,
      } as Vehicle);
    }
  }, [
    lastScannedLicensePlate.recentLicenseRef,
    lastScannedLicensePlate.createdAt,
  ]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!lastScannedLicensePlate.createdAt) return;

      const now = new Date().getTime();
      const diff = (now - lastScannedLicensePlate.createdAt) / 1000;

      if (diff > lastScannedLicensePlate.licensePlateClearDelay) {
        updateVehicle({ code: "" } as Vehicle);
      } else {
        updateVehicle({
          code: lastScannedLicensePlate.recentLicenseRef,
        } as Vehicle);
      }
    }, (lastScannedLicensePlate.licensePlateClearDelay + 1) * 1000);

    return () => clearInterval(timer);
  }, [vehicle]);

  return { vehicle, updateVehicle };
};

export default useLastScannedLicensePlate;
