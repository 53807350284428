import React, { FunctionComponent } from "react";
import { StyleSheet, View, ViewProps } from "react-native";

import { Countries } from "./styles";
import { Margins } from "../../constant";
import { CountryCode } from "../country/list";
import { Title } from "../typography";

const styles = StyleSheet.create({
  container: {
    paddingVertical: Margins.tiny,
    paddingHorizontal: Margins.small,
  },
});

export interface Props extends ViewProps {
  country?: CountryCode;
  huge?: boolean;
}

const LicensePlateCountry: FunctionComponent<Props> = (props) => {
  const { country, style, children, huge, ...otherProps } = props;
  if (!country) return null;
  const containerStyle = Countries.default.country?.containerStyle;
  const textStyle = Countries.default.country?.textStyle;
  return (
    <View style={[styles.container, containerStyle, style]} {...otherProps}>
      <Title
        style={[textStyle, huge ? { fontSize: 28, fontWeight: "100" } : {}]}
      >
        {Countries[country]?.countryCode || country.toUpperCase()}
      </Title>
      {children}
    </View>
  );
};

export default LicensePlateCountry;
