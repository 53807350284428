import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import { useTranslation } from "react-i18next";

import {
  PublicStart,
  PublicStarted,
  PublicEnd,
  PublicScan,
  PublicPay,
  PublicConfirm,
  PublicScannedStart,
} from "../screens";

export type PublicStackParamList = {
  PublicStart: { spotId: string };
  PublicScannedStart: { spotId: string };
  PublicStarted: { bookingId: string; goBack?: boolean };
  PublicEnd: { spotId: string; checkout?: boolean };
  PublicCheckout: { spotId: string; checkout?: boolean };
  PublicConfirm: { bookingId: string; checkout?: boolean };
  PublicConfirmCheckout: { bookingId: string; checkout?: boolean };
  PublicScan: { bookingId: string };
  PublicPay: { bookingId: string; action?: string; checkout?: boolean };
  PublicPayCheckout: { bookingId: string; action?: string; checkout?: boolean };
  PublicCompleted: { bookingId: string; action?: string; checkout?: boolean };
  PublicCompletedCheckout: {
    bookingId: string;
    action?: string;
    checkout?: boolean;
  };
};

const Stack = createNativeStackNavigator<PublicStackParamList>();

const PublicRoutes = () => {
  const { t } = useTranslation();
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="PublicStart"
        component={PublicStart}
        options={{ title: `My Way | ${t("Start parking")}` }}
      />
      <Stack.Screen
        name="PublicScannedStart"
        component={PublicScannedStart}
        options={{ title: `My Way | ${t("Start parking")}` }}
      />
      <Stack.Screen
        name="PublicStarted"
        component={PublicStarted}
        options={{ title: `My Way | ${t("Booking started")}` }}
      />
      <Stack.Screen
        name="PublicEnd"
        component={PublicEnd}
        options={{ title: `My Way | ${t("End parking")}` }}
      />
      <Stack.Screen
        name="PublicCheckout"
        component={PublicEnd}
        options={{ title: `My Way | ${t("End parking")}` }}
        initialParams={{ checkout: true }}
      />
      <Stack.Screen
        name="PublicConfirm"
        component={PublicConfirm}
        options={{ title: `My Way | ${t("End parking")}` }}
      />
      <Stack.Screen
        name="PublicConfirmCheckout"
        component={PublicConfirm}
        options={{ title: `My Way | ${t("End parking")}` }}
        initialParams={{ checkout: true }}
      />
      <Stack.Screen
        name="PublicScan"
        component={PublicScan}
        options={{ title: `My Way | ${t("Payment")}` }}
      />
      <Stack.Screen
        name="PublicPay"
        component={PublicPay}
        options={{ title: `My Way | ${t("Pay booking")}` }}
      />
      <Stack.Screen
        name="PublicPayCheckout"
        component={PublicPay}
        options={{ title: `My Way | ${t("Pay booking")}` }}
        initialParams={{ checkout: true }}
      />
      <Stack.Screen
        name="PublicCompleted"
        component={PublicPay}
        options={{ title: `My Way | ${t("Pay booking")}` }}
      />
      <Stack.Screen
        name="PublicCompletedCheckout"
        component={PublicPay}
        options={{ title: `My Way | ${t("Pay booking")}` }}
        initialParams={{ checkout: true }}
      />
    </Stack.Navigator>
  );
};

export default PublicRoutes;
