import { RouteProp, useLinkTo } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React, { useState, useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";
import { OtpInput } from "react-native-otp-entry";

import { Page } from "../../../components";
import { AuthStackParamList } from "../../../routes/auth";
import { Spot, useStore, withAuthentication } from "../../../stores";
import {
  Button,
  Colors,
  Margins,
  Radius,
  Text,
  Title,
} from "../../../storybook";

const styles = StyleSheet.create({
  content: {
    padding: Margins.large,
  },
  text: {
    marginBottom: Margins.small,
  },
  button: {
    marginTop: Margins.regular,
  },
  spot: {
    borderTopStartRadius: Radius.regular,
    borderTopEndRadius: Radius.regular,
    overflow: "hidden",
  },
});

type EmailNavigationProp = StackNavigationProp<
  AuthStackParamList,
  "AuthEmailConfirm"
>;
type EmailRouteProp = RouteProp<AuthStackParamList, "AuthEmailConfirm">;

interface Props {
  navigation: EmailNavigationProp;
  route: EmailRouteProp;
}
const SMSConfirm = (props: Props) => {
  const { route } = props;
  const { next, spotId } = route.params || {};
  const [smsCode, setSMSCode] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [timer, setTimer] = useState(0);

  const savedCode = window.localStorage.getItem("code");
  const phoneNumber = window.localStorage.getItem("phoneNumber") ?? "";
  const store = useStore();
  const { auth } = store;
  const linkTo = useLinkTo();
  const { t } = useTranslation();

  const spot = useMemo(
    () => spotId && new Spot(`spots/${spotId}`, {}, store),
    [spotId]
  );

  useEffect(() => {
    if (savedCode) {
      verifyCode(savedCode);
    }
  }, []);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  const verifyCode = async (code: string) => {
    setLoading(true);
    try {
      await auth.confirmSMSCode({ code });

      if (Platform.OS === "web") {
        window.location.href = next || "/";
      } else {
        linkTo(next || "/");
      }
    } catch (error) {
      // TODO: show error
      console.error("Error: ", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const sendLink = async () => {
    setLoadingResend(true);
    try {
      await auth.sendSMSCode({ phoneNumber });
    } catch (e) {
      // @ts-ignore
      const errorMessage = errorCodes[e?.code || ""];
      console.error(e);

      if (errorMessage) {
        setError(errorMessage);
      } else {
        setError(t("An error occurred. Please try again later"));
      }
    } finally {
      setLoadingResend(false);
      setTimer(10);
    }
  };

  const errorCodes = {
    "auth/missing-phone-number": t("Please insert a phone number"),
    "auth/invalid-phone-number": t("Please insert a valid phone number"),
    "auth/too-many-requests": t("Too many requests. Try again later"),
  };

  return (
    <Page spot={typeof spot === "string" ? undefined : spot}>
      <View style={{ width: "100%", marginTop: Margins.regular }}>
        <Title level={1} style={styles.text}>
          {error ? (
            <Trans>Verification Failed</Trans>
          ) : (
            <Trans>Enter Your Verification Code</Trans>
          )}
        </Title>
        {error ? (
          <>
            <Text>
              <Trans>
                The code could not be verified. Please double-check and try
                again.
              </Trans>
            </Text>
            <Button
              style={styles.button}
              title={t("Try again")}
              onPress={() => setError(false)}
            />
          </>
        ) : savedCode ? (
          <Text>
            <Trans>Your code is being verified...</Trans>
          </Text>
        ) : (
          <>
            <Text style={styles.text}>
              {phoneNumber && phoneNumber.length > 0
                ? t(
                    "Enter the 6-digit code sent to $1 to complete verification."
                  ).replace("$1", phoneNumber)
                : t(
                    "Enter the 6-digit code sent to your phone to complete verification."
                  )}
            </Text>
            <OtpInput
              numberOfDigits={6}
              onTextChange={(text) => setSMSCode(text)}
              onFilled={(text) => verifyCode(text)}
              autoFocus
              type="numeric"
              theme={{
                containerStyle: { maxWidth: 340, alignSelf: "center" },
                pinCodeContainerStyle: { backgroundColor: Colors.white },
                focusedPinCodeContainerStyle: { borderColor: Colors.forest },
                focusStickStyle: { backgroundColor: Colors.forest },
              }}
              textInputProps={{ caretHidden: true }}
            />
            <Button
              style={styles.button}
              title={t("Verify Code")}
              onPress={() => verifyCode(smsCode)}
              loading={loading}
              disabled={smsCode.length !== 6 || loading}
            />
            <Button
              style={styles.button}
              title={t("Resend Code")}
              centre="rotateCw"
              color="transparent"
              onPress={sendLink}
              loading={loadingResend}
              disabled={loadingResend}
            />
          </>
        )}
      </View>
    </Page>
  );
};

export default withAuthentication(observer(SMSConfirm));
