import React, { ReactNode } from "react";
import {
  StyleSheet,
  Pressable,
  PressableProps,
  View,
  ActivityIndicator,
} from "react-native";

import { Colors as ConstantColors, Margins, Radius } from "../../constant";
import IconView, { IconName, Color as IconColor } from "../icon/icon";
import { Text } from "../typography";

const SizeStyles = {
  huge: {
    height: 70,
    level: 3 as const,
    iconSize: 30,
  },
  large: {
    height: 60,
    level: 3 as const,
    iconSize: 26,
  },
  regular: {
    height: 50,
    level: 4 as const,
    iconSize: 22,
  },
  small: {
    height: 42,
    level: 5 as const,
    iconSize: 20,
  },
  tiny: {
    height: 30,
    level: 6 as const,
    iconSize: 16,
  },
};
export type Size = keyof typeof SizeStyles;
export const Sizes = Object.keys(SizeStyles) as Size[];

interface ColorStyle {
  text: string;
  background?: string;
  pressed?: {
    background?: string;
    text?: string;
  };
  disabled?: {
    opacity: number;
  };
}
export const ColorStyles: { [color: string]: ColorStyle } = {
  default: {
    text: ConstantColors.forest,
    background: ConstantColors.sky,
    pressed: {
      background: ConstantColors.darkBlue,
    },
  },
  green: {
    text: ConstantColors.forest,
    background: ConstantColors.lime,
    pressed: {
      background: ConstantColors.gableGreen,
    },
  },
  purple: {
    text: ConstantColors.white,
    background: ConstantColors.lightPurple,
    pressed: {
      background: ConstantColors.darkPurple,
    },
  },
  red: {
    text: ConstantColors.white,
    background: ConstantColors.lightRed,
    pressed: {
      background: ConstantColors.darkRed,
    },
  },
  black: {
    text: ConstantColors.white,
    background: ConstantColors.forest,
    pressed: {
      background: ConstantColors.darkGrey,
    },
  },
  white: {
    text: ConstantColors.forest,
    background: ConstantColors.veryLightGrey,
    pressed: {
      background: ConstantColors.lightGrey,
    },
  },
  orange: {
    text: ConstantColors.white,
    background: ConstantColors.lightOrange,
    pressed: {
      background: ConstantColors.darkOrange,
    },
  },
  grey: {
    text: ConstantColors.forest,
    background: ConstantColors.lightGrey,
    pressed: {
      text: ConstantColors.grey,
    },
  },
  transparent: {
    text: ConstantColors.forest,
    pressed: {
      text: ConstantColors.grey,
    },
    disabled: {
      opacity: 0.3,
    },
  },
  outline: {
    text: ConstantColors.forest,
    pressed: {
      text: ConstantColors.grey,
    },
  },
};
export type Color = keyof typeof ColorStyles;
export const Colors = Object.keys(ColorStyles) as Color[];

export type Icon = IconName;

const styles = StyleSheet.create({
  container: {},
  background: {
    paddingHorizontal: Margins.regular,
    justifyContent: "center",
    borderRadius: Radius.small,
  },
  iconBackground: {
    justifyContent: "center",
    borderRadius: Radius.small,
  },
  disabled: {
    opacity: 0.6,
  },
  content: {
    flexDirection: "row",
    alignItems: "center",
  },
  iconLeft: {
    marginRight: Margins.small,
  },
  iconCentre: {
    marginRight: Margins.small,
  },
  iconRight: {
    marginLeft: Margins.small,
  },
  left: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  centre: {
    flexDirection: "row",
    alignContent: "space-around",
    flex: 1,
    justifyContent: "center",
  },
  spinner: {
    alignSelf: "flex-end",
    marginLeft: Margins.small,
  },
});

interface ContentProps {
  pressed: boolean;
}

export interface Props extends PressableProps {
  title?: string | ReactNode;
  size?: Size;
  color?: Color;
  left?: Icon;
  right?: Icon;
  centre?: Icon;
  loading?: boolean;
  disabled?: boolean;
  variant?: "default" | "icon" | "link";
}
const Button = (props: Props) => {
  const {
    title,
    size,
    color,
    left,
    right,
    centre,
    loading,
    disabled,
    variant = "default",
    ...otherProps
  } = props;

  const colorStyle = ColorStyles[color || variant];

  const { height, level, iconSize } = SizeStyles[size || "regular"];
  let sizeStyle: any = {
    height,
    minWidth: height,
  };

  if (variant === "icon") {
    sizeStyle = {
      height: 50,
      width: 50,
      padding: 0,
    };
  }

  const renderContent = (isPressed: boolean) => {
    const text = (isPressed && colorStyle.pressed?.text) || colorStyle.text;
    const color = text as IconColor;

    if (variant === "icon") {
      return (
        <View
          style={[
            styles.content,
            disabled && styles.disabled,
            disabled && colorStyle.disabled,
          ]}
        >
          <View style={styles.centre}>
            {loading ? (
              <ActivityIndicator color={color} />
            ) : (
              <IconView
                name={centre as Icon}
                color={color}
                size={24}
                iconStyle={{ marginRight: 0 }}
              />
            )}
          </View>
        </View>
      );
    }

    return (
      <View
        style={[
          styles.content,
          disabled && styles.disabled,
          disabled && colorStyle.disabled,
        ]}
      >
        <View
          style={centre || (!centre && !left) ? styles.centre : styles.left}
        >
          {left && (
            <IconView
              name={left}
              style={styles.iconLeft}
              color={color}
              size={iconSize}
            />
          )}
          {centre && (
            <IconView
              name={centre}
              style={styles.iconCentre}
              color={color}
              size={iconSize}
            />
          )}
          <Text level={level} style={{ color }}>
            {title}
          </Text>
        </View>
        {loading ? (
          <ActivityIndicator style={styles.spinner} color={color} />
        ) : (
          right && (
            <IconView
              name={right}
              style={styles.iconRight}
              color={color}
              size={iconSize}
            />
          )
        )}
      </View>
    );
  };

  const renderLink = (isPressed: boolean) => {
    return (
      <View style={[styles.background, sizeStyle]}>
        {renderContent(isPressed)}
      </View>
    );
  };

  const renderSolid = (isPressed: boolean) => {
    const { background } = (isPressed && colorStyle.pressed) || colorStyle;
    const backgroundStyle = {
      backgroundColor: background,
    };

    return (
      <View
        style={[
          styles.background,
          backgroundStyle,
          sizeStyle,
          disabled && styles.disabled,
          disabled && colorStyle.disabled,
        ]}
      >
        {renderContent(isPressed)}
      </View>
    );
  };

  const renderButton = (contentProps: ContentProps) => {
    const { pressed } = contentProps;
    const { background } = colorStyle;
    if (!background) {
      return renderLink(pressed);
    }
    return renderSolid(pressed);
  };

  return (
    <Pressable
      style={[styles.container, sizeStyle]}
      disabled={disabled}
      {...otherProps}
    >
      {renderButton}
    </Pressable>
  );
};

export default Button;
