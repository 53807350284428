import firebase from "firebase/compat/app";
import { Document } from "firestorter";

export interface LastScannedLicensePlateData {
  createdAt: firebase.firestore.Timestamp;
  licensePlate: string;
  licenseRef: string;
}
class LastScannedLicensePlate extends Document<LastScannedLicensePlateData> {
  licensePlateClearDelay = 40; //sec
  get createdAt() {
    if (!this.data.createdAt) return undefined;
    return this.data.createdAt.toDate().getTime();
  }
  get recentLicenseRef() {
    return this.data.licenseRef;
  }
}

export default LastScannedLicensePlate;
