import { saveAs } from "file-saver";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import Option from "../../components/booking/reservation/option";
import { Page } from "../../components/general";
import { useStore, withAdmin } from "../../stores";
import {
  Margins,
  Title,
  TextInput,
  Button,
  Colors,
  Text,
  SelectInput,
} from "../../storybook";

type VoucherType = "credit";

const GenerateVouchers = () => {
  const {
    parking: { admin },
  } = useStore();
  const { t } = useTranslation();
  const [amount, setAmount] = useState<string>();
  const [type, setType] = useState<VoucherType>("credit");
  const [count, setCount] = useState<string>();
  const [result, setResult] = useState<
    { id: string; value: string; type: VoucherType }[] | null
  >(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const popupStyle = { alert: { contentStyle: styles.options } };

  const handleGenerate = async () => {
    try {
      setLoading(true);
      const parsedAmount = parseInt(amount || "0", 10);
      const parsedCount = parseInt(count || "0", 10);

      if (isNaN(parsedAmount) || isNaN(parsedCount)) {
        throw new Error("Invalid input: Amount and Count must be numbers");
      }

      if (parsedAmount <= 0 || parsedCount <= 0) {
        throw new Error("Amount and Count must be greater than zero");
      }

      const cappedAmount = Math.min(parsedAmount, 100);
      const cappedCount = Math.min(parsedCount, 100);

      const result = await admin.generateVouchers({
        type,
        value: cappedAmount,
        count: cappedCount,
      });

      const vouchers = result.vouchers;

      // Extract voucher codes and join them with newlines
      setResult(
        Object.values(vouchers).map((voucher: any) => ({
          id: voucher.id,
          value: voucher.value,
          type: voucher.type,
        }))
      );
    } catch (error) {
      // Handle errors (e.g., show an error message to the user)
      console.error("Error generating vouchers:", error);
      // @ts-ignore
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    if (result) {
      const csvContent =
        "data:text/csv;charset=utf-8," +
        [
          "Code,Amount,Type",
          ...result.map(
            (voucher) => `${voucher.id},${voucher.value},${voucher.type}`
          ),
        ].join("\n");
      const encodedUri = encodeURI(csvContent);
      saveAs(encodedUri, "vouchers.csv");
    }
  };

  return (
    <Page>
      <View style={{ gap: Margins.small, width: "100%" }}>
        {result ? (
          <>
            <Title level={1} style={{ marginBottom: Margins.small }}>
              {t("Generated vouchers")}
            </Title>
            <Button
              title={t("Download Vouchers")}
              left="download"
              color="green"
              style={{ marginTop: Margins.small }}
              onPress={handleDownload}
            />
            <Button
              title={t("Generate more")}
              left="arrowleft"
              style={{ marginTop: Margins.small }}
              onPress={() => setResult(null)}
            />
          </>
        ) : (
          <>
            <Title level={1} style={{ marginBottom: Margins.small }}>
              {t("Generate vouchers")}
            </Title>
            <Text>Amount</Text>
            <TextInput
              keyboardType="numeric"
              onChangeText={setAmount}
              value={amount}
              placeholder="eg. €10 or 10%"
            />
            <Text>Count</Text>
            <TextInput
              keyboardType="numeric"
              onChangeText={setCount}
              value={count}
              placeholder="eg. 10x (max 100)"
            />
            <Text>Type</Text>
            <SelectInput
              disabled
              // TODO: add discount option with a promotion form
              options={[
                {
                  id: "credit",
                  render: () => <Option title="Credit" style={styles.option} />,
                },
              ]}
              value={type}
              picker={{ ...popupStyle }}
              onChange={(value: string) => setType(value as VoucherType)}
              style={{ backgroundColor: Colors.moonMist }}
            />
            <Button
              title={t("Generate")}
              right="arrowright"
              onPress={handleGenerate}
              loading={loading}
            />
            {error && (
              <Text style={[styles.spacing, { color: Colors.darkRed }]}>
                {error}
              </Text>
            )}
          </>
        )}
      </View>
    </Page>
  );
};

export default withAdmin(GenerateVouchers);

const styles = StyleSheet.create({
  spacing: {
    marginHorizontal: Margins.large,
    marginBottom: Margins.regular,
  },
  option: {
    marginVertical: Margins.small,
    paddingVertical: Margins.small,
    marginHorizontal: Margins.regular,
  },
  options: {
    minWidth: 500,
  },
});
