import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  TouchableOpacity,
  StyleSheet,
  View,
  NativeSyntheticEvent,
} from "react-native";

import Option from "./option";
import { PaymentOptionIdeal } from "../../../stores/parking/payment";
import {
  Button,
  Colors,
  Margins,
  PopUp,
  PopUpProps,
  TextInput,
  Title,
} from "../../../storybook";

const styles = StyleSheet.create({
  option: {
    paddingHorizontal: Margins.regular,
  },
  separator: {
    borderTopWidth: 0.5,
    borderColor: Colors.lightGrey,
  },
  name: {
    padding: Margins.regular,
  },
  input: {
    marginVertical: Margins.regular,
  },
});

const banks = [
  {
    image: require("../../../../assets/images/banks/abn_amro.png"),
    title: "ABN Amro",
    id: "abn_amro",
  },
  {
    image: require("../../../../assets/images/banks/asn_bank.png"),
    title: "ASN Bank",
    id: "asn_bank",
  },
  {
    image: require("../../../../assets/images/banks/bunq.png"),
    title: "Bunq",
    id: "bunq",
  },
  {
    image: require("../../../../assets/images/banks/ing.png"),
    title: "ING",
    id: "ing",
  },
  {
    image: require("../../../../assets/images/banks/knab.png"),
    title: "Knab",
    id: "knab",
  },
  {
    image: require("../../../../assets/images/banks/rabobank.png"),
    title: "Rabobank",
    id: "rabobank",
  },
  {
    image: require("../../../../assets/images/banks/regiobank.png"),
    title: "Regiobank",
    id: "regiobank",
  },
  {
    image: require("../../../../assets/images/banks/sns_bank.png"),
    title: "SNS Bank",
    id: "sns_bank",
  },
  {
    image: require("../../../../assets/images/banks/triodos_bank.png"),
    title: "Triodos Bank",
    id: "triodos_bank",
  },
  {
    image: require("../../../../assets/images/banks/van_lanschot.png"),
    title: "Van Lanschot",
    id: "van_lanschot",
  },
];

export type Data = PaymentOptionIdeal;

interface Props extends PopUpProps {
  onSave?: (value: Data) => void;
  loading?: boolean;
  error?: string;
}
const IDeal = (props: Props) => {
  const { loading, onSave, error, visible, onRequestClose, ...otherProps } =
    props;
  const [bankName, setBankName] = useState<string>();
  const [cardName, setCardName] = useState<string>();
  const { t } = useTranslation();

  const close = (e: NativeSyntheticEvent<any>) => {
    setBankName(undefined);
    onRequestClose && onRequestClose(e);
  };

  const select = (id: string) => () => {
    setBankName(id);
  };

  const save = () => {
    if (!bankName || !cardName) return;
    onSave && onSave({ bankName, cardName });
  };
  return (
    <>
      <PopUp
        onRequestClose={close}
        {...otherProps}
        visible={visible !== false && !bankName}
      >
        {banks.map((bank) => (
          <TouchableOpacity key={bank.id} onPress={select(bank.id)}>
            <Option
              title={bank.title}
              image={bank.image}
              style={styles.option}
            />
          </TouchableOpacity>
        ))}
      </PopUp>
      <PopUp
        onRequestClose={close}
        {...otherProps}
        visible={visible !== false && !!bankName}
      >
        <View style={styles.name}>
          <Title level={3}>
            <Trans>Add your name</Trans>
          </Title>
          <TextInput
            style={styles.input}
            label={t("Name on card")}
            onChangeText={setCardName}
            disabled={loading}
          />
          <Button
            title={error || t("Add card")}
            onPress={save}
            color={error ? "red" : undefined}
            disabled={loading || !cardName}
            loading={loading}
          />
        </View>
      </PopUp>
    </>
  );
};

export default IDeal;
